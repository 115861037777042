.Ehs-form-title {
  display: flex;
  gap: 15px;
  cursor: pointer;
  margin-top: 15px;
}
.Ehs-form-table td {
  padding: 13px !important;
}
.form-count {
  display: flex;
  gap: 20px;
  color: #3c4856;
  height: 27px;
  margin-top: 16px;
}
.ehs-form-sec {
  display: flex;
  justify-content: space-between;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
}
.app-count {
  color: #00ab66;
  font-weight: 600;
}
.pen-count {
  color: #a59014;
  font-weight: 600;
}
.rej-count {
  color: #e31e24;
  font-weight: 600;
}
.approved-border {
  border-right: 1px solid #a9b0bd;
}
.add-item-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #475467;
  border-radius: 4px;
  color: #3c4856;
  padding: 12px 22px;
}
.ehs-part {
  display: flex;
  gap: 20px;
}
.btn-sec {
  display: flex;
  gap: 15px;
}
.Ehs-form-table th {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #3c4856;
  font-weight: 600;
}
.search-icon {
  color: #90a3b9;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px !important;
  width: 24px !important;
}
.search-table-row {
  position: relative;
  padding: 5px 16px 5px 40px !important;
  background: #f1f1ff;
  height: 50px;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  display: flex;

  /* min-width: 400px;
      max-width: 100%; */
}
.search-table-row input {
  background: transparent;
  border: 0;
  min-width: 80px;
  width: 100%;
}
.search-table-row input:focus {
  outline: none;
}
.selectDropdown {
  padding: 0 !important;
  border: none !important;
  margin-left: -17px;
  width: 63px !important;
  border-bottom: none;
}
.approved-status {
  width: 20px;
  height: 20px;
  background: #00ab66;
  border-radius: 2px;
  opacity: 1;
}
.rejected-status {
  width: 20px;
  height: 20px;
  background: #e31e24;
  border-radius: 2px;
  opacity: 1;
}
.pending-status {
  width: 20px;
  height: 20px;
  background: #a59014;
  border-radius: 2px;
  opacity: 1;
}
.add-material-box {
  padding: 12px 20px;
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  width: 383px;
  height: auto;
  border-radius: 4px;
  background: #ffffff;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  z-index: 1;
}
.add-material-div {
  display: flex;
  justify-content: space-between;
}
.add-material-div h6 {
  font-size: 18px;
  font-weight: 600;
}
.materialcode-dropdown {
  width: 100%;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 4px;
}
.dropdown-area h6 {
  font-size: 14px;
  margin-bottom: 4px !important;
}
.reset-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #086b3e;
  border-radius: 4px;
  color: #004828;
  padding: 15px 54px;
}
.submit-btn {
  background: #0e8951 0% 0% no-repeat padding-box;
  border: 1px solid #086b3e;
  border-radius: 4px;
  color: #ffffff;
  padding: 15px 54px;
}
.submit-btn-disable {
  background: #0e8951 0% 0% no-repeat padding-box;
  border: 1px solid #086b3e;
  border-radius: 4px;
  color: #ffffff;
  padding: 15px 54px;
  cursor: not-allowed !important;
  opacity: 0.4;
  pointer-events: none;
}
.btn-div {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.box-container {
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  /* White with 50% opacity */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.disposalBox {
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1286px;
  height: 700px;
  border-radius: 4px;
  background: #ffffff;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  z-index: 1;
}
.reset-close-btn-disable {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
  border: 1px solid #d41218 !important;
    color: #333333 !important;
    pointer-events: none !important;
}
.rdrStaticRangeLabel{
  color: gray;
}
.rdrDateDisplayWrapper{
background-color: white !important;
}
.rdrDateRangePickerWrapper{
  position: absolute;
  right: -407px;
  top: 12px;
}
.datepicker-input{
  height: 51px;
    border-radius: 4px;
    border: 1px solid;
    text-align: center;
}
.datepicker-input:focus-visible{
  outline: none;
}
.title-width {
  width: 60%;
}
.closeIcon-width {
  width: 40%;
  justify-content: end;
  display: flex;
  gap: 10px;
}
.disposalTitle {
  background: #0e8951 0% 0% no-repeat padding-box;
  height: 70px;
  color: #ffffff;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  padding: 20px;
}
.disposalTitle h5 {
  text-align: end;
}
.disposal-desc {
  text-align: center;
  color: #333333;
}
.disposal-detail {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.disposal-detail h6 {
  font-size: 15px;
  color: #333333;
}
.approved-title {
  text-align: center;
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}
.approvedpopup {
  padding: 12px 13px;
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  width: 383px;
  border-radius: 4px;
  background: #ffffff;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  z-index: 9999;
}
.approval-desc h6 {
  font-weight: 700;
  color: #333333;
}
.approve-textarea {
  width: 100%;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
}
.reject-btn {
  background-color: #e31e24 !important;
  border: 1px solid #d41218 !important;
}

.reset-close-btn {
  border: 1px solid #d41218 !important;
  color: #333333 !important;
}
@media (min-width: 1024px) and (max-width: 1536px) {
  .disposalBox {
    height: 460px;
    overflow: auto;
  }
  .ehs-form-sec{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .analytics-datepicker{
    margin-top: 20px;
  }
}
@media (min-width: 767px) and (max-width: 1024px) {
  .disposalBox {
    width: 700px;
    overflow-x: auto;
  }
  .ehs-form-sec{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .analytics-datepicker{
    margin-top: 20px;
  }
}
@media (max-width: 1300px) {
  .disposalBox {
    width: 900px;
  }
}
