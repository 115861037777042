.userManagementTable {
  padding-top: 20px;
}
/* .updateUserDropdow.form-select {
    border: 1px solid #000 !important;
    font-size: 12px !important;
} */
.createAdmin {
  margin-bottom: 0;
  margin-top: 5px;
  background: #e8f8f2;
  color: #00ab66;
  padding: 6px 16px;
  border-radius: 16px;
  border: 1px solid #00ab66;
}
.createAdminDeleteUser {
  height: 400px;
  width: 634px;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  padding: 30px 20px 30px 20px;
  z-index: 99;
}
.adminNextButton {
  float: right;
  padding: 5px 20px;
  background: #0E8951;
  color: white;
  border: none;
  border-radius: 4px;
}
.createCloseIcon{
  margin-top: -22px !important;
  margin-right: -8px !important;
}
.admindisableNextButton{
  float: right;
  padding: 5px 20px;
  background: #0E8951;
  color: white;
  border: none;
  border-radius: 4px;
  opacity: 0.5;
  pointer-events: none;
}
.adminUserCard {
  height: 286px;
  flex-wrap: wrap;
  overflow: auto;
}
.adminUser {
  margin-bottom: 0;
  margin-top: 5px;
  background: #ededed;
  color: #667d73;
  padding: 6px 16px;
  border-radius: 16px;
  border: 1px solid #667d73;
}
.user-item {
  margin-bottom: 10px; /* Add margin-bottom for vertical spacing */
}

.user-name {
  margin-left: 10px; /* Add margin-left for spacing between checkbox and name */
}
.updateUserDropdown {
  border: 1px solid #000;
  font-size: 14px;
  padding: 10px;
}
.updateUserDropdown option {
  font-size: 16px;
}
.form-select-lg.updateUserDropdow {
  border: 1px solid #000 !important;
  font-size: 12px !important;
}
.updateUserDropdown.form-select:focus {
  box-shadow: none !important;
  border: 1px solid #000;
}
.ClientManagement.InviteUser-Button.userManagement {
  padding: 6px 56px;
}

.disabledRow {
  pointer-events: none; /* Disable pointer events */
}

.deviceManagementHeader-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.deviceManagementTitle-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.addDevice-buttonWrap {
  display: flex;
  gap: 10px;
}

.deviceManagementTitle {
  color: #222d39;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding-left: 10px;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.manageDepartment-button {
  background-color: #fff;
  color: #3C4856;
  border: 1px solid #475467;
  border-radius: 4px;
  padding: 0px 20px;
}

.deviceManagementTable {
  background: #a9b0bd;
  border: 1px solid #a9b0bd;
  border-radius: 8px;
  overflow: hidden;
}

.deviceManagementTable,
tr,
td,
th {
  border: 1px solid #a9b0bd !important;
}

.deviceManagementTable tr {
  background-color: #fff;
}

.deviceManagementTable th {
  /* padding: 16px 20px !important; */
  line-height: 1 !important;
  /* font-size: 18px !important; */
  font-size: 16px;
  font-weight: 400;
}

.deviceManagementTable-title th {
  color: #3c4856 !important;
  font-weight: 700;
}

.search-table-row {
  position: relative;
  padding: 5px 16px 5px 40px !important;
  background: #f1f1ff;
  height: 50px;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  display: flex;
  /* min-width: 400px;
    max-width: 100%; */
}

.search-table-row input {
  background: transparent;
  border: 0;
  min-width: 80px;
  width: 100%;
}

.search-table-row input:focus {
  outline: none;
}

.search-icon {
  color: #90a3b9;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px !important;
  width: 24px !important;
}

.cloudActiveImg {
  cursor: pointer;
}

.inviteUserModal {
  /* height: auto; */
  /* height: 525px; */
  min-height: 525px;
  width: auto;
  padding: 12px 20px;
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  z-index: 1;
}

.useInviteWrap {
  margin-bottom: 6px;
}

.updateUserDropdown {
  border: 1px solid #000;
  font-size: 14px;
  padding: 10px;
}

.updateUserDropdown option {
  font-size: 16px;
}

.departmentWrap #exampleInputEmail1::placeholder {
  color: #001323;
  font-size: 14px;
}

.userInviteLabelplaceHolder::placeholder {
  font-size: 12px;
  color: #001323;
}

.form-select-lg.updateUserDropdow {
  border: 1px solid #000 !important;
  font-size: 12px !important;
}

.updateUserDropdown.form-select:focus {
  box-shadow: none !important;
  border: 1px solid #000;
}

.ClientManagement.InviteUser-Button {
  background-color: #0E8951;
  color: #fff;
  border: 1px solid #086B3E;
  border-radius: 3px;
  /* width: auto; */
  margin: 0 auto;
  display: flex;
  /* width: 55%; */
  padding: 9px 37px !important;
  margin-top: 20px;
  /* margin-bottom: 10px; */
  text-align: center;
  text-transform: capitalize;
}

.deleteConfirmation-Modal {
  height: auto;
  width: auto;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  padding: 30px 80px 30px 80px;
  z-index: 9999;
}

.deleteConfirmation-title {
  text-align: center;
  color: #0E8951;
  font-weight: 700 !important;
}

.deleteConfirmation-btnWrap {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.deleteConfirmation-Yesbtn {
  background-color: #0E8951;
  color: #fff;
  border: 1px solid #086B3E;
  border-radius: 4px;
  /* width: auto; */
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  letter-spacing: 1.8px;
  text-transform: capitalize;
}

.deleteConfirmation-Nobtn {
  background-color: #a9b0bd;
  color: #001323;
  border: 1px solid #a9b0bd;
  border-radius: 4px;
  /* width: auto; */
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  letter-spacing: 1.8px;
  text-transform: capitalize;
}

@media (max-width: 767.98px) {
  .page-header .header-btn-group {
    width: 100%;
  }

  .search-table-row {
    order: 5;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .MuiInputBase-input {
    min-width: 35px !important;
  }

  .MuiInputBase-input {
    min-width: 35px !important;
  }
}

@media (min-width: 899px) and (max-width: 1024px) {
  .inviteUserModal {
    width: 400px;
    /* top: 75%; */
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {

  .inviteUserModal {
    width: 400px;
    /* top: 75%; */
    /* min-height: 400px; */
  }
}

@media (min-width: 899px) and (max-width: 1024px) {
  .inviteUserModal {
    width: 400px;
    /* top: 75%; */
  }

  /* .expandCompanyBorder{
        display: none !important;
    } */

  .ClientManagement.InviteUser-Button {
    width: 46%;
  }

  /* .clientManagement-updateModal {
        top: 26%;
    } */
  .clientManagement-updateModal {
    top: 68% !important;
    right: 3px;
  }
} 

@media (min-width: 767px) and (max-width: 899.98px) {
  .cardCompany::before {
    display: none;
  }

  .expandCompanyBorder {
    /* display: none !important; */
    border-left: none;
    height: 0;
  }

  .inviteUserModal {
    width: 400px;
    /* top: 75%; */
    /* min-height: 400px; */
  }

  .ClientManagement.InviteUser-Button {
    width: 46%;
  }

  .clientManagement.companyText {
    font-size: 16px !important;
  }

  .clientManagement-companySmalltext {
    color: #5473A5;
    font-size: 12px !important;
  }

  /* .clientManagement-updateModal {
        top: 32%;
    } */
  .clientManagement-updateModal {
    top: 82% !important;
    right: 3px;
  }
}
