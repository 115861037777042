.loader-container {
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
   /* White with 50% opacity */
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 99999;
 }
 
 .dots {
   width: 60px;
   height: 40px;
   background: radial-gradient(circle closest-side, #e15b64 90%, #0000) 0% 50%, radial-gradient(circle closest-side, #e15b64 90%, #0000) 50% 50%, radial-gradient(circle closest-side, #000 90%, #0000) 100% 50%;
   background-size: calc(100%/3) 36%;
   background-repeat: no-repeat;
   animation: dots-7ar3yq 1s infinite linear;
   position: fixed;
 }
 
 @keyframes dots-7ar3yq {
   20% {
     background-position: 0% 0%, 50% 50%, 100% 50%
   }
 
   40% {
     background-position: 0% 100%, 50% 0%, 100% 50%
   }
 
   60% {
     background-position: 0% 50%, 50% 100%, 100% 0%
   }
 
   80% {
     background-position: 0% 50%, 50% 50%, 100% 100%
   }
 }
  
  /* .dots-3 {
   width: 60px;
   aspect-ratio: 2;
   --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
   background: 
     var(--_g) 0%   50%,
     var(--_g) 50%  50%,
     var(--_g) 100% 50%;
   background-size: calc(100%/3) 50%;
   animation: d3 1s infinite linear;
 }
 @keyframes d3 {
     20%{background-position:0%   0%, 50%  50%,100%  50%}
     40%{background-position:0% 100%, 50%   0%,100%  50%}
     60%{background-position:0%  50%, 50% 100%,100%   0%}
     80%{background-position:0%  50%, 50%  50%,100% 100%}
 } */
  