.addAccessManagement.selectDropDown{
    width: 90%;
    padding: 10px 0px 10px 0px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.addAccessManagement.selectDropDown.Roles{
    width: 50%;
    padding: 10px 0px 10px 0px;
    border-radius: 4px;
    margin-bottom: 20px;
}
.accessDepartment-textWrap{
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.addAccessManagement-fieldWrap {
    /* padding: 12px; */
    /* padding: 9px; */
    padding: 6px 0px 6px 0px;
    /* height: 40px; */
    /* width: 100%; */
    width: 90%;
    background-color: #F1F1FF;
    position: relative;
    cursor: pointer;
    /* color: #F1F1FF; */
}
.activeAccessDepartmentImg{
    position: absolute;
    right: -4px;
    top: 0px;
    height: 54px;
}
.accessManagement.subModules{
    display: flex;
    align-items: center;
    gap: 20px;
}
.accessManagement.switchWrap{
    width: 20%;
}

.manageDepartment-card {
    /* height: 750px; */
    min-height: 750px;
}

.manageDepartment-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.manageDepartment-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.managementDepartment-card {
    background-color: #f1f1ff;
    min-height: 620px;
    padding: 10px 10px 10px 10px;
}

.Plantmanagemet.submit {
    background-color: #0E8951;
    color: #fff;
    border: 1px solid #086B3E;
    border-radius: 4px;
    padding: 11px 60px;
    margin-top: 20px;
    text-transform: capitalize;
    float: right;
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .addAccessManagement.selectDropDown{
        width: 100%;
    }
    .addAccessManagement.selectDropDown.Roles{
        width: 100%;
    }
    .accessDepartment-textWrap{
        width: 100%;
    }
    .addAccessManagement-fieldWrap{
        width: 100%;
    }
}