button {
    border: 1px solid #086B3E;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    color: #fff;
    background-color: #0E8951;
    /* width: 100%; */
    padding: 16px;
    font-weight: 500;
}
button.bordered {
    color: #001323;
    background-color: #F4F7FE;
}