.reportManagement-schedule-dropdown {
  border: 1px solid #000;
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  width: 50%;
}

.reportManagement-schedule-card {
  background-color: #F1F1FF;
  min-height: 620px;
  margin-right: 10px;
  padding: 12px 20px 0px 20px;
}

.reportManagement-deviceLists-btn {
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  padding: 6px 20px 6px 20px;
  background-color: #0E8951;
  color: #fff;
}
.alarm-department-highlight {
  width: 90%;
  background-color: #0E8951c2;
  color: #fff;
  position: relative;
  cursor: pointer;
  overflow-wrap: anywhere;
}
.reportManagement-deviceLists-btn1 {
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  padding: 6px 20px 6px 20px;
  background-color: #0E8951ad;
  color: #fff;
}

.reportMangement-titleWrap {
  display: flex;
  justify-content: space-between;
}

.reportManagement-taglistModal-title {
  color: #fff;
  font-size: 20px !important;
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}

.fff {
  min-height: 400px;
}

.card-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /* Add this line to hide overflowing content */
}

.reportManagement-tagListModal {
  max-height: 80vh;
  overflow: auto;
  z-index: 99;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.table-container {
  height: calc(80vh - 48px);
  /* Subtract the height of the header */
  overflow: auto;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: red;
  z-index: 99;
}

.scrollable-body {
  overflow-y: scroll;
  color: #0E8951;
}

.closeImg {
  width: 30px;
  cursor: pointer;
  background-color: white;     
}

.reportmanagement-box-datepicker {
  width: 50% !important;
}

.deviceManagementHeader-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.deviceManagementTitle-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.www {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-tab {
  background-color: white;
  border: 1px solid #087B47;
  color: #001323;
  padding: 13px 45px;
  font-weight: 500;
  font-size: 18px;
  border-radius: unset;
}

.device-tab {
  background-color: #0E8951;
  border: 1px solid #087B47;
  color: white;
  padding: 13px 45px;
  font-weight: 500;
  font-size: 18px;
  border-radius: unset;
}
.configure-tab-report{
  background-color: white;
  border: 1px solid #103168;
  color: #103168;
  padding: 14px 22px;
  font-weight: 500;
  font-size: 16px;
}

.alarmManagement-submit-disable {
  background-color: #0E8951;
  color: #fff;
  border: 1px solid #086B3E;
  border-radius: 4px;
  padding: 11px 60px;
  margin-top: 20px;
  text-transform: capitalize;
  float: right;
  opacity: 0.5;
  pointer-events: none;
}

.alarmMangementCard-useListCard1 {
  background-color: #F1F1FF;
  min-height: 620px;
  margin-right: 10px;
}

.alarmManagementCard-Roleswrap {
  display: flex;
  align-items: center;
}

@media(min-width:899px) and (max-width:1600px){
.reportmanagement-box-datepicker {
    width: 50% !important;
  }
}
@media(min-width:767px) and (max-width:899px) {
.reportmanagement-box-datepicker {
    width: 50% !important;
  }
}