.shimmer-header-plant{
    background: #e0e0e0;
    height: 15px;
    padding: 0px 65px;
    border-radius: 4px;
}

.plantmanagementDepartment-fieldWrap-shimmer {
    /* padding: 12px; */
    padding: 9px;
    /* height: 40px; */
    width: 90%;
    /* background-color: #f1f1ff; */
    position: relative;
    cursor: pointer;
    /* color: #F1F1FF; */
    animation: shimmerAnimation 3s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
}
.shimmerassign-icon {
    background: #e0e0e0;
    border-radius: 17%;
    width: 25px;
    height: 25px;
    margin-right: 16px;
    margin-top: 15px;
}
.assignDevice-shimmer{
    animation: shimmerAnimation 3s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    padding: 18px 16px 18px 10px !important
}
.header-plant-shimmer{
    background: #e0e0e0;
    height: 15px;
    width: 183%;
    /* padding: 0px 65px; */
    border-radius: 4px;
}
.header-plant-shimmer1{
    background: #e0e0e0;
    height: 15px;
    width: 118%;
    /* padding: 0px 65px; */
    border-radius: 4px;
}
.configuration-plant-shimmer{
    background: #e0e0e0;
    height: 29px !important;
    width: 13%;
    /* padding: 0px 65px; */
    border-radius: 4px;
}
.configuration-alarm-shimmer{
    background: #e0e0e0;
    height: 29px !important;
    /* width: 13%; */
    padding: 0px 65px;
    border-radius: 4px;
}
.header-alarm-shimmer{
    background: #e0e0e0;
    height: 15px;
    /* width: 118%; */
    padding: 0px 65px;
    border-radius: 4px;
}
.alarm-shimmer-effect{
    padding: 27px 16px !important;
    animation: shimmerAnimation 3s infinite linear forwards;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
}