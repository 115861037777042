.analytics-tabs-btn {
    display: flex;
  }
  .Analytics-tabs-overview {
    background: #0E8951;
    border: 1px solid #0E8951;
    color: white;
    padding: 15px;
    font-weight: 500;
    font-size: 15px;
    width: 221px;
    border-radius: unset;
  }
  
  .Reports-tabs-overview {
    background: white;
    border: 1px solid #0E8951;
    color: #333333;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    width: 221px;
    border-radius: unset;
  }