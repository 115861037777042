.Material-tabs {
  background: #0e8951;
  border: 1px solid #0e8951;
  color: white;
  padding: 14px 32px;
  font-weight: 500;
  font-size: 18px;
  border-radius: unset;
}
.Location-tabs {
  background: white;
  border: 1px solid #0e8951;
  color: #333333;
  padding: 14px 32px;
  font-weight: 500;
  font-size: 18px;
  border-radius: unset;
}
.material-grid-pop{
  height: 188px;
  overflow: auto;
}

.consumption-grid-card {
  display: flex;
  gap: 10px;
}
.consumerData-box {
  background-color: #fff;
  min-height: 688px;
  border-radius: 6px;
  padding: 10px;

}
.consumer-text-disc {
  background-color: #f5f5f5;
  padding: 6px 10px;
}
.Title{
  font-size: 13px !important;
  margin-bottom: 2px !important;
  
}
.Data-chart-title{
  font-size: 14px;
  margin-bottom: 2px !important;
}
.search-textfield {
  padding-top: 9px !important;
  width: 100%;
}
.furnace-grid{
  height: 105px;
  overflow: auto;
}
.grid-switch-unit span {
  float: right !important;
}
.device-unit{
  height: 105px;
  overflow: auto;
}
.location-grid{
  height: 82px;
  overflow: auto;
}
.export-data-btn{
  background: #0E8951;
  color: white;
  padding: 13px;
  width: 100%;
  border: #0E8951;
}
.doughnut-chartText-unit {
  font-size: 13px !important;
  position: absolute;
  top: 52%;
  left: 51%;
}
.donut-chart-css {
  height: 195px !important;
}
.doughnut-chart1{
  position: relative;
}
.doughnut-chartText {
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 49%;
  transform: translate(-50%, -50%);
}

.datepicker-color .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #c0255f !important;
}

.datepicker-color .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #c0255f !important;
}

.second-datepicker-color .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #e66595 !important;
}

.second-datepicker-color .MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #e66595 !important;
}

.datepicker-color
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  color: #c0255f !important;
}

.datepicker-color .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #c0255f !important;
}

.second-datepicker-color
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  color: #e66595 !important;
}

.second-datepicker-color .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #e66595 !important;
}

.datepicker-color .css-1ald77x {
  color: #c0255f !important;
}

.datepicker-color .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #c0255f !important;
}

.second-datepicker-color .css-1ald77x {
  color: #e66595 !important;
}

.second-datepicker-color .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #e66595 !important;
}

.datepicker-color .css-p0rm37 {
  color: #c0255f !important;
}

.datepicker-color .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #c0255f !important;
}

.second-datepicker-color .css-p0rm37 {
  color: #e66595 !important;
}

.second-datepicker-color .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #e66595 !important;
}