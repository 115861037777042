.footer-title {
  background-color: #f1f1ff;
  color: #4d5963;
  text-align: end;
  margin: 0;
  position: fixed;
  bottom: 0px;
  right: 0;
  padding: 1px;
  width: 100%;
  z-index: 99;
  padding: 0px 23px;
}
.approved-legend {
    width: 16px;
    height: 16px;
    background: #00ab66 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 0px;
    margin-top: 3px;
    border-radius: 50%;
}
.rejected-legend {
  width: 16px;
  height: 16px;
  background: #E31E24 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right: 0px;
  margin-top: 3px;
  border-radius: 50%;
}
.pending-legend {
  width: 16px;
  height: 16px;
  background: #A59014 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right: 0px;
  margin-top: 3px;
  border-radius: 50%;
}
