.box-tabs-table .Mui-selected {
  background: #fff;
  color: #000 !important;
  position: relative;
}
.box-tabs-table .MuiTabs-flexContainer {
  padding-bottom: 13px;
}
.box-tabs-table .MuiTabs-flexContainer button {
  overflow: initial;
}
.Ehs-form-table tr:nth-child(even) {
  background-color: #F4F7FE !important;
}

.box-tabs-table .Mui-selected > span {
  background: transparent;
  height: 0px;
  width: 0px;
  border-bottom: 10px solid #fff;
  border-right: 10px solid #fff;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: calc(100% - 12px);
  left: 50%;
  transform: translatex(-50%) rotate(45deg);
  box-shadow: 1px 1px 1px #a9b0bd;
}
/* .box-tabs-table .MuiTabs-indicator {
    display: none;
  } */
.export-image {
  height: 52px;
  cursor: pointer;
}
.materialcode-dropdown::placeholder {
  font-size: 12px;
  color: #999;
}

.material-tabs {
  width: 159px !important;
  border: 1px solid #a9b0bd !important;
  border-radius: 6px 0px 0px 0px !important;
  overflow: initial;
  color: #3c4856 !important;
}
.location-tabs {
  width: 159px !important;
  border: 1px solid #a9b0bd !important;
  border-radius: 0px 0px 0px 0px !important;
  overflow: initial;
  color: #3c4856 !important;
}
.container-tabs {
  width: 159px !important;
  border: 1px solid #a9b0bd !important;
  border-radius: 0px 6px 0px 0px !important;
  overflow: initial;
  color: #3c4856 !important;
}
.deleteConfirmation-Modal {
    height: auto;
    width: auto;
    /* padding: 12px 20px; */
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    border-radius: 6px !important;
    padding: 40px 57px 40px 57px !important;
    z-index: 99;
  }
  .deleteConfirmation-title {
    text-align: center;
    color: #0E8951;
    font-weight: 700 !important;
  }
  .importCSVModal {
    /* height: auto; */
    min-height: 150px;
    width: 400px;
    padding: 30px 30px;
    position: absolute;
    /* top: 30%; */
    right: 2%;
    top: 21%;
    /* left: 50%; */
    transform: translate(0%, 10%);
    box-shadow: 0px 4px 26px #888888 !important;
    z-index: 99;
  }
  
  .importCSVModal-text {
    text-align: center;
    color: #0E8951;
    margin-bottom: 20px;
    font-size: 500;
  }
  .ImportCSV.Input {
    /* width: 50%; */
    margin-bottom: 20px;
  }
  
  .ImportCSV-BtnWrap {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .ImportCSV-AddBtn {
    /* background-color: #1c407b; */
    color: #fff;
    /* border: 1px solid #1e3760; */
    border-radius: 4px;
    width: 120px;
    text-transform: capitalize;
    padding: 8px;
    /* margin-right: 22px;    */
  }
  
  .ImportCSV-CancelBtn {
    /* background-color: #1c407b; */
    color: #fff;
    /* border: 1px solid #1e3760; */
    border-radius: 4px;
    width: 120px;
    text-transform: capitalize;
    padding: 8px;
    /* margin-right: 22px;    */
  }

  @media (min-width: 899px) and (max-width: 1199px) {
    .importCSVModal {
      left: 50%;
      top: 62%;
      transform: translate(-50%, -50%);
    }
  }
  
  @media (min-width: 767px) and (max-width: 899px) {
    .importCSVModal {
      left: 50%;
      top: 60%;
      transform: translate(-50%, -50%);
    }
  }