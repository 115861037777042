.batch-link{
    text-decoration: underline !important;
}
.notification-datepicker{
    position: absolute;
    right: -327px;
    top: 12px;
}
.history-datepicker{
    position: absolute;
    right: -189px;
    top: 12px;
}
