/* ========== SignIn-form-css start ========== */

.main-container {
    min-height: 100vh;
    background-color: #fff;
}

.createPassword.signIn-form-wrap {
    padding: 40px 40px 15px 40px !important;
    position: relative;
}

.signIn-logo {
    margin: 0 auto;
    display: flex;
    height: 77px;
}

.logo-deafult {
    margin: 0 auto;
    display: flex;
}

.forgotPassword {
    /* font-size: 36px; */
    font-size: 28px;
}

.SingIn-unlock {
    /* padding: 15px 0; */
    padding: 15px 0px 36px 0px;
    /* gap: 10px; */
    gap: 4px;
    font-weight: 500;
}

.signIn-footer {
    gap: 20px;
    /* padding: 30px 0px 40px 0px; */
    padding: 0px 0px 0px 0px;
}

.signIn-footer h6 {
    /* font-size: 14px; */
    font-size: 12px;
    font-weight: 400;
}

.signIn-footer-lines h6 {
    font-weight: 400;
}

.signIn-footer-lines span {
    font-weight: 600;
}

.forgotPassword-lines {
    /* font-weight: 400; */
    /* font-size: 19px; */
    text-align: center;
}


.forgotPassword-lines h5 {
    font-size: 16px;
}

/* ============================================================== */
.createPassword-text {
    text-align: center;
    padding-bottom: 30px;
    font-weight: 600;
}

.createPasswordText {
    padding: 20px 0px 40px 0px;
    font-size: 30px;
}

.createPassword-button {
    background-color: #F4F7FE;
    color: #000;
    border: 1px solid #086B3E;
    border-radius: 6px;
    width: 100%;
    padding: 12px;
    text-transform: capitalize;
}

.createPassword-button.getOTP {
    background-color: #0E8951;
    color: white;
    border: 1px solid #086B3E !important;
    border: 0;
    border-radius: 6px;
    width: 100%;
    /* padding: 16px; */
    padding: 12px;
    /* letter-spacing: 1.8px; */
    text-transform: capitalize;
}

.createPassword.signIn-footer-lines {
    /* margin-top: 30px; */
    position: fixed;
    bottom: 10px;
}

.reEnterPassword-TextWrap h6 {
    font-size: 11px;
    color: #001323;
}

/* .reEnterPassword-TextWrap{
    margin: 0;
} */
/* ============================================================== */

@media(max-width:1024px) and (max-width:1400px) {
    .forgotPassword {
        font-size: 28px !important;
    }
}

@media(max-width:1025px) and (max-width:1170px) {
    .signIn-Footer {
        gap: 2px !important;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .signIn-form-wrap {
        padding: 30px 40px 5px 40px !important;
    }

    .createPassword.forgotPassword {
        padding-top: 10px;
    }

    .signIn-Footer {
        flex-direction: column;
        gap: 0px !important;
        /* padding: 0px 0px 40px 0px !important; */
        bottom: 95px;
    }

    .forgotPassword-lines h5 {
        /* font-size: 18px; */
        font-size: 15px;
    }

    .forgotPassword {
        font-size: 24px;
    }

    .SingIn-unlock {
        margin-bottom: 14px;
    }

    /* ============================================= */
    .createPassword-text {
        padding-bottom: 30px;
        font-weight: 600;
        font-size: 30px;
    }
}

@media(max-width:1025px) and (max-width:1170px) {
    .signIn-footer {
        gap: 2px !important;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .signIn-form-wrap {
        /* padding: 70px 40px 175px 40px !important; */
        padding: 70px 40px 15px 40px !important;
    }

    .forgotPassword-lines h5 {
        font-size: 15px !important;
    }

    .signIn-footer {
        /* flex-direction: column; */
        gap: 65px !important;
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }
}


/* ========== SignIn-form-css end ========== */

@media(max-width:576px) {

    /* .signIn-logo {
        width: 100%;
    } */
    .SignIn-keepLogin-wrap {
        flex-direction: column;
        gap: 10px;
    }

    .viewIcon {
        position: absolute;
        top: 42.8% !important;
        right: 15% !important;
    }

    .signIn-footer {
        /* flex-direction: column; */
        gap: 5px !important;
        padding: 0px 0px 40px 0px !important;
    }
}

/* ========== SignIn-form-css start ========== */

.signIn-form-wrap {
    padding: 70px 40px 15px 40px;
    position: relative;
}

.signIn-logo {
    margin: 0 auto;
    display: flex;
    height: 77px;
}

.abc {
    display: flex;
    justify-content: space-between !important;
    gap: 100px !important;
    padding-bottom: 20px;
}

.viewIcon {
    position: absolute;
    top: 47.80%;
    right: 8%;
}

.SignIn-keepLogin-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
}

.keepLogin-wrap {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.keepLogin-label {
    font-size: 18px;
}

.forgotPassword {
    /* font-size: 36px; */
    font-size: 28px;
}

.forgotPassword-link,
.forgotPassword-link:hover {
    color: #001323;
}

.SingIn-unlock {
    /* padding: 15px 0; */
    padding: 15px 0px 36px 0px;
    /* gap: 10px; */
    gap: 4px;
    font-weight: 500;
}

.signIn-footer {
    gap: 20px;
    padding: 30px 0px 40px 0px;
}

.signIn-footer h6 {
    font-weight: 400;
}

.signIn-footer-lines h6 {
    font-weight: 400;
}

.signIn-footer-lines span {
    font-weight: 600;
}

.forgotPassword-lines {
    /* font-weight: 400; */
    /* font-size: 19px; */
    text-align: center;
}

.resend-btn {
    display: flex;
    justify-content: space-around;
}

.resend-btn button {
    background-color: transparent;
    color: #001323;
    font-weight: 500;
    padding: 0;
    border: 0;
    text-decoration: underline;
}

.OTP input {
    height: 60px !important;
    /* width: 70px !important; */
    width: 50px !important;
    background-color: #F4F7FE;
    border-radius: 4px 4px 0px 0px;
    padding-bottom: 2px;
    border: 0;
    border-bottom: 2px solid #d1d6d6;
    margin: 0 auto;
}

.otp-input.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.view.icon.OTP {
    width: 20px;
}



/* ========== SignIn-form-css end ========== */
@media (min-width: 1600px) and (max-width: 1900px) {
    .OTP input {
        width: 35px !important;
        height: 45px !important;
    }

    .otp-input.wrap {
        justify-content: center;
    }
}

@media(max-width:1024px) and (max-width:1400px) {
    .forgotPassword {
        font-size: 28px !important;
    }
}

@media (min-width: 899.98px) and (max-width: 1599.98px) {

    /* input[type="password"] {
        width: 48px! important;
        height: 44px!important;
    } */
    .OTP input {
        /* width: 48px! important; */
        /* width: 45px !important; */
        /* width: 40px !important; */
        width: 35px !important;
        height: 45px !important;
    }

    .otp-input.wrap {
        justify-content: center;
    }

    /* .xyz {
        width: 48px! important;
        height: 44px!important;
    } */
}

@media(max-width:1025px) and (max-width:1170px) {
    .signIn-Footer {
        gap: 2px !important;
    }

    .OTP input {
        /* width: 48px! important; */
        width: 42px !important;
        height: 44px !important;
    }

    .otp-input.wrap {
        justify-content: center;
    }
}

@media(min-width:899.98px) and (max-width:1024px) {
    .signIn-form-wrap {
        padding: 30px 40px 5px 40px !important;
    }

    .signIn-footer {
        flex-direction: column;
        gap: 0px !important;
        /* padding: 0px 0px 40px 0px !important; */
        bottom: 95px;
    }

    .OTP input {
        /* width: 48px! important; */
        width: 34px !important;
        height: 36px !important;
    }

    .forgotPassword-lines {
        /* font-weight: 400; */
        font-size: 18px;
        /* text-align: center; */
    }

    .SingIn-unlock {
        margin-bottom: 14px;
    }

    .signIn-footer {
        /* gap: 65px !important; */
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }

    .view.icon.OTP {
        width: 10px;
    }
}

@media (min-width: 767px) and (max-width: 899.98px) {
    .signIn-form-wrap {
        /* padding: 70px 40px 175px 40px !important; */
        padding: 70px 40px 15px 40px !important;
    }

    .signIn-Footer {
        gap: 65px !important;
        /* padding: 0px 0px 40px 0px !important; */
        padding: 0px 0px 0px 0px !important;
    }

    .otp-input.wrap {
        justify-content: center;
    }
}

@media(max-width:576px) {
    .viewIcon {
        top: 42.8% !important;
        right: 15% !important;
    }

    .signIn-footer {
        /* flex-direction: column; */
        gap: 5px !important;
        padding: 0px 0px 40px 0px !important;
    }
}

