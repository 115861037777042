input[type="checkbox"] {
  height: 18px !important;
  width: 18px !important;
  cursor: pointer !important;
  accent-color: #0E8951 !important;
}

.keep-login-label {
  font-size: 18px !important;
}

.keep-login-wrap {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.signIn-logo {
  margin: 0 auto;
  display: flex;
  height: 77px;
}

.ReCAPTCHA-R {
  padding: 10px 0 10px !important;
}

.submit-two-factor-btn {
  width: 100%;
}

.cancle-two-factor-btn {
  background-color: white;
  width: 100%;
  color: black;
}

.two-factor {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.form-footer {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 60px !important;
}

.forgot-link,
.forgot-link:hover {
  color: #001323 !important;
}

.form-footer.custom-margin {
  margin-bottom: 9px !important;
}

.main-container {
  min-height: 100vh;
  background-color: #fff;
}

.slider-container {
  height: 100%;
  overflow: hidden;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide>div,
.single-slide {
  height: 100%;
}

.single-slide {
  position: relative;
}

.single-slide .overlay {
  background: linear-gradient(rgba(255, 255, 255, 0), #000);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 475px;
  z-index: 0;
}

.single-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slider-detail {
  position: absolute;
  bottom: 100px;
  color: #fff;
  width: 60%;
  left: -50px;
  text-align: left;
  transition: 1s;
  opacity: 0;
  z-index: 1;
}

.slider-detail h2 {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 400;
}

.slider-detail p {
  font-size: 1.2rem;
}

.slick-slide.slick-active .slider-detail {
  left: 25px;
  opacity: 1;
}

.slick-dots {
  bottom: 60px;
  width: auto;
  left: 20px;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li button:before {
  font-size: 14px;
  opacity: 0.5;
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #e31e24;
  opacity: 1;
}

.logo {
  margin-top: 80px;
}

.hikar-logo img {
  margin-top: 60px;
  margin-bottom: 40px;
  max-width: 120px !important;

}

.logo img,
.hikar-logo img {
  max-width: 262px;
}

.form-container {
  padding: 0px 40px 90px 40px;
  position: relative;
}

.footer-content {
  position: absolute;
  bottom: 0;
  text-align: left;
  font-size: 14px;
}

@media (max-width: 899.98px) {
  .slider-wrapper {
    display: none;
  }

  .form-container {
    padding: 0px 20px 90px 20px;
    position: relative;
  }
}