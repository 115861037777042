.userProfile-companyDetails {
    padding-top: 20px;
}

.customeTextField.personal.personalTab {
    width: 100%;
}
.tab-panel-data{
    padding: 24px !important;
}

/* .css-1ff8729-MuiInputBase-root-MuiFilledInput-root.Mui-disabled:before {
    border-bottom-style: 1px solid #F4f7fe  !important;
} */
.css-1ff8729-MuiInputBase-root-MuiFilledInput-root.Mui-disabled:before {
    border-bottom-style: solid !important;
}

.css-dvhgws.Mui-disabled::before {
    border-bottom-style: solid !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.abcd {
    left: -24px !important;
}

.MuiInputBase-input.MuiFilledInput-input {
    background-color: #F4F7FE;
}

.css-1thjcug {
    background-color: #F4F7FE !important;
}

.css-1thjcug:hover {
    background-color: #F4F7FE !important;
}

.css-1pnqat0 {
    background-color: #F4F7FE !important;
}

.css-1pnqat0 {
    background-color: #F4F7FE !important;
}

.mobileNumber-Dropdown {
    margin-bottom: 0px;
    /* padding: 27px 6px 0px 14px; */
    /* font-size: 14px; */
}

.mobileNumber-DropdownWrap {
    display: flex;
    padding: 29px 0px 0px 10px;
    font-size: 20px;
    cursor: pointer;
}

.dropdown-container {
    position: relative;
    margin-right: 10px;
}

.dropdown-container select {
    /* for Firefox */
    /* -moz-appearance: none; */
    /* for Safari, Chrome, Opera */
    /* -webkit-appearance: none; */
}

/* for IE10 */
.dropdown-container select::-ms-expand {
    /* display: none; */
}
.PersonalTabn-dropdown{
    margin-top: 20px;
    /* margin-right: 50px !important; */
}


/* .dropdownDownArrow {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: -8px;
    margin-right: 20px;
} */

/* select:focus-visible {
    border: none !important;
} */

select:focus {
    /* appearance: none; */
}

/* select:focus {
    outline: none !important;
    border: none !important ;
} */

.personalTab-verifyButton{
    /* border: 1px solid #086B3E; */
    background-color:#086B3E ;
    color: #fff;
    font-size: 12px;
    border: 0;
    border-radius: 4px;
    padding: 4px 10px;
}

@media(min-width:767.98px) and (max-width:899px) {
    .userProfile-companyDetails {
        padding-top: 0px !important;
    }
}