.reportMangement-reportDetailsWrap {
    display: flex;
    flex-direction: column;
}

.reportMangement-reportDetails-ContenetWrap {
    display: flex;
    align-items: center;
    gap: 5px;
}
.add-report-btn{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #475467;
    border-radius: 4px;
    color: #3C4856;
    padding: 13px 24px;
}
.reportmanagement-text {
    margin-bottom: 0px !important;
}

.reportManagement-flex-container {
    display: flex;
    align-items: center;
}

.reportManagement-flex-container div:first-child {
    flex-basis: 14%;
}

.reportManagement-flex-container div:last-child {
    flex-basis: 50%;
}

.reportManagement-flex-container div button {
    margin-right: 4px;
    margin-bottom: 4px;
}

.reportDetailsWrap {
    display: flex;
    align-items: center;
}

.icon-pointer{
    cursor: pointer;
}

.deviceManagementHeader-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.deviceManagementHeader-wrap .backArrow {
    cursor: pointer;
}

.deviceManagementTitle-wrap {
    display: flex;
    align-items: center;
    gap: 4px;
}

.manageDepartment-button {
    background-color: #fff;
    color: #3C4856;
    border: 1px solid #475467;
    border-radius: 4px;
    padding: 0px 20px;
}

.addDevice-buttonWrap {
    display: flex;
    gap: 10px;
}

.alarmManagement.userCardModal {
    height: auto;
    width: 30%;
    /* padding: 12px 20px; */
    /* position: absolute; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 26px #888888 !important;
    border-radius: 6px !important;
    z-index: 99;
}

.addPlant-titleWrap {
    padding: 0;
    text-align: center !important;
    background-color: #0E8951;
    padding: 24px 0px 14px 0px;
}

.alarmManagement.settingIcon {
    position: absolute;
    right: 20px;
    width: 28px;
}

.alarmMangemnt.userCardInnerWrap {
    padding: 16px 10px 10px 10px;
    overflow: auto;
    max-height: 250px;
}

.addPlant-title {
    color: #fff;
    font-weight: 500 !important;
}

@media (min-width: 899px) and (max-width: 1024px) {
    .alarmManagement.userCardModal {
        width: 40%;
        top: 50%;
    }
}   

@media (min-width: 899px) and (max-width: 1024px) {
    .alarmManagement.userCardModal {
        width: 40%;
        top: 50%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .device-box {
        width: auto;

    }

    .hikemm-device-box {
        justify-content: center;
        gap: 34px;
    }

    .bridgeDevice-headerContent {
        margin-top: 19px;
    }

    .deviceManagementHeader-wrap {
        flex-wrap: wrap;
        gap: 15px;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .deviceManagementHeader-wrap {
        flex-wrap: wrap;
        gap: 15px;
    }
}