.alarmManagement.groupDetails-wrap {
  padding: 10px 16px 10px 16px;
}

.groupDetails-innerWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addDeviceDetailsCardetails-text {
  margin: 0;
}

.alarmManagement.groupDetailsBtnWrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

.alarmManagement.groupIcon {
  height: 20px;
}

.clientManagement-updateModal-data {
  position: absolute;
  /* right: 25px; */
  z-index: 999;
  width: 75px;
  /* min-height: 70px; */
  padding: 6px 0px 4px 0px;
  border: 1px solid #0e8951;
  overflow: visible !important;
  left: 20px;
  margin-top: 8px;
}

.alarm-management-update-data {
  right: 159px !important;
  left: auto !important;
  padding: 20px;
  width: auto;
  border: 1px solid #d9dde5;
}

.alarm-management-update-data::after {
  border-top: #d9dde5 solid 1px !important;
  border-left: #d9dde5 solid 1px !important;
}

.clientMangement-updatedataWrap-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alarmManagementConfiguration-btn {
  border: 1px solid #103168;
  background-color: #fff;
  color: #001323;
  font-size: 14px;
  border-radius: 4px;
  padding: 7px 21px;
  /* width: 25%; */
  /* width: 18%; */
  height: 10%;
}

.reportMangement-reportDetailsWrap {
  display: flex;
  flex-direction: column;
}

.reportManagement-flex-container {
  display: flex;
  align-items: center;
}

.reportManagement-flex-container div:first-child {
  flex-basis: 8%;
}

.reportManagement-flex-container div:last-child {
  flex-basis: 50%;
}

.reportManagement-flex-container div button {
  margin-right: 4px;
  margin-bottom: 4px;
}

.reportmanagement-text {
  margin-bottom: 0px !important;
}

.alarmManagement-deviceList-button {
  border: 0;
  border-radius: 2px;
  background-color: #ddddee;
  font-size: 14px;
}

.clientMangement-downIcon {
  display: flex;
  margin: 0 auto;
  cursor: pointer;
}
