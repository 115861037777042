.dashboard-wrapper {
    padding-top: 74px;
    padding: 24px;
  }
  .input-ehs-month{
    padding: 6px 0px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid gray;
  }
  .datepicker-ehs{
    position: absolute;
    top: 24%;
    right: 2px;
  }
  .ehs-datepicker-data{
    top: 20% !important;
    position: absolute !important;
    right: 40px !important;
  }
  .dashbord-card {
    box-shadow: 0px 12px 20px #aaaaaa29 !important;
    border-radius: 16px !important;
    padding: 48px;
    cursor: pointer;
    transition: 0.5s;
  }
  
  .dashbord-card:hover {
    box-shadow: 0px 12px 20px #aaaaaac4 !important;
  }
  
  .dashbord-card .module-item {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  .dashbord-card .module-item .module-icon {
    height: 90px;
    width: 90px;
  }
  
  .dashbord-card .module-item .module-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .dashbord-card .module-item .module-name h2 {
    color: #333333;
    margin: 0;
    font-weight: 700;
    text-align: left;
    line-height: 1.2;
    font-size: 36px;
    text-transform: capitalize;
  }
  
  .form-input-class {
    width: 120px;
    height: 36px;
    border: 1px solid #475467;
    border-radius: 4px;
    opacity: 1;
    font: normal normal normal 14px/19px Roboto;
    color: #001323;
    margin-left: 37px;
  }
  
  .section-title {
    text-align: left;
    margin-left: 92px;
    margin-bottom: 25px;
  }
  
  .production-card {
    padding: 36px 0 36px 0;
    border-radius: 16px !important;
    border: 1px dashed #0E8951;
    box-shadow: 0px 12px 20px #aaaaaa29 !important;
  }
  
  .production-card .production-item {
    width: 25%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    border-right: 2px solid #dce1ea;
  }
  
  .production-card .production-item:last-child {
    border-right: 0px solid #dce1ea;
  }
  
  .production-card .production-item .production-icon {
    height: 60px;
    width: 60px;
  }
  
  .production-card .production-item .production-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .production-card .production-item .production-name p {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }
  
  .production-card .production-item .production-name h2 {
    margin: 0;
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    color: #001323;
    line-height: 1;
  }
  
  .title-block {
    padding: 0 36px;
    margin-bottom: 36px;
    flex-wrap: wrap;
  }
  
  .title-block h2 {
    font-size: 24px;
    text-transform: capitalize;
  }
  
  .title-block .select-menu {
    margin: 0 !important;
  }
  
  .title-block .select-menu .select-menu-inside > div:first-child {
    padding: 10px 6px 3px 15px;
    text-align: left;
  }
  
  .title-block .select-menu-label:not(.MuiFormLabel-filled, .Mui-focused) {
    top: -9px;
  }
  
  .title {
    left: 0;
    bottom: 0;
    position: fixed !important;
    width: 100%;
    padding: 5px 20px;
    background: #f1f1ff;
  }
  
  .insideProductionTable {
    padding: 0 !important;
    padding-left: 26px !important;
    background: #ffff;
  }
  
  .insideProductionTable td {
    padding: 11px !important;
  }
  
  td.MuiTableCell-root.MuiTableCell-paddingCheckbox {
    padding: 17px !important;
  }
  
  .productionstatus-table td {
    padding: 12px !important;
    text-align: center;
  }
  
  .productionstatus-table tr:nth-child(even) {
    background-color: #fff !important;
  }
  
  .productionstatus-table tr:nth-child(odd) {
    background-color: #f1f1ff !important;
  }
  .productionstatus-table tr:hover {
    background-color: #f1f1ff;
  }
  .medium-btn {
    height: 16px;
    width: 16px;
    background-color: #f6e163;
    border-radius: 50%;
  }
  .low-btn {
    height: 16px;
    width: 16px;
    background-color: #9933ff;
    border-radius: 50%;
  }
  
  .csvReport td {
    padding: 14px !important;
  }
  .selectedactiveClass {
    background-color: #1e90ff;
    color: #ffff;
  }
  
  .uploadFile {
    width: -webkit-fill-available;
  }
  .csv-report {
    padding: 50px 17px !important;
  }
  .MuiFilledInput-root {
    background-color: #F4F7FE !important;
    color: #001323 !important;
  }
  
  .MuiFilledInput-root:hover {
    background-color: #f1f1ff !important;
    color: #001323 !important;
  }
  
  .cancleButton {
    margin-top: -40px;
    height: 24px;
    cursor: pointer;
  }
  
  .highpriority {
    width: 18px;
    height: 18px;
    background: #e31e24 0% 0% no-repeat padding-box;
    border-radius: 2px;
  }
  
  .mediumpriorty {
    width: 18px;
    height: 18px;
    background: #f6e163 0% 0% no-repeat padding-box;
    border-radius: 2px;
  }
  
  .lowpriority {
    width: 18px;
    height: 18px;
    background: #9933ff 0% 0% no-repeat padding-box;
    border-radius: 2px;
  }
  
  @media (max-width: 959.98px) {
    .production-card .production-item {
      width: 50%;
    }
  
    .production-card .production-item:nth-child(even) {
      border-right: 0px solid #dce1ea;
    }
    
  }
  
  @media (max-width: 599.98px) {
    .dashboard-wrapper {
      padding-top: 64px;
      padding: 16px;
    }
  
    .dashbord-card {
      padding: 24px;
      border-radius: 8px !important;
    }
  
    .dashbord-card .module-item {
      gap: 20px;
    }
  
    .dashbord-card .module-item .module-icon {
      height: 60px;
      width: 60px;
    }
  
    .dashbord-card .module-item .module-name h2 {
      font-size: 24px;
    }
  
    .production-card {
      row-gap: 40px;
    }
  
    .production-card .production-item {
      width: 100%;
      justify-content: start;
      padding: 0 24px;
    }
  
    .production-card .production-item {
      border-right: 0px solid #dce1ea;
    }
  
    .product {
      flex-wrap: wrap;
      gap: inherit;
    }
  }
  
  @media (max-width: 1199.98px) {
    .production-card .production-item .production-name p {
      font-size: 15px;
    }
  
    .production-card .production-item .production-name h2 {
      font-size: 22px;
    }
    .ehs-datepicker-data{
      top: 36% !important;
      position: absolute !important;
      right: 40px !important;
    }
  }
  
  .file-logo-container {
    display: flex !important;
    align-items: right !important; /* Left the icon and text field vertically */
    border-bottom: 2px solid rgb(130, 125, 125) !important; /* Sets a red, 2-pixel solid bottom border */
  }
  
  .file-upload-icon {
    position: absolute !important;
    top: 122px !important;
    right: 25px !important;
    margin-right: 10px !important;
    font-size: 24px !important;
    height: 28px !important;
    width: auto !important;
  }
  
  .uploadFile-button {
    text-align: left !important;
    font: normal normal normal 16px/19px Roboto !important;
    color: #001323 !important;
    background-color: #f4f7fe !important;
    letter-spacing: 0px !important;
    opacity: 1 !important;
    height: 56px !important;
    width: 260px !important;
    margin-top: 10px !important;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer !important;
  }
  
  .csvReport-popup-box {
    height: 285px !important;
    width: 300px !important;
    top: 224px !important;
  }
  
  .input-data-type-csv {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    height: 46px !important;
  }
  
  .ufile {
    margin: 17px 59px 17px 10px !important;
    padding: 0 0px 0 0 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  /* 17px 59px 17px 10px !important */
  @media (max-width: 822px) {
    .csvReport-popup-box {
      height: 285px !important;
      width: 300px !important;
      top: 279px !important;
      left: 35% !important;
    }
    .ehs-datepicker-data{
      top: 17% !important;
      position: absolute !important;
      right: 40px !important;
    }
  }
  
  @media (max-width: 1026px) {
    .csvReport-popup-box {
      height: 285px !important;
      width: 300px !important;
      top: 279px !important;
      left: 39% !important;
    }
  }
  @media(min-width: 1024px) and (max-width: 1536px){
    .ehs-datepicker-data{
      top: 30% !important;
      position: absolute !important;
      right: 40px !important;
    }
  }
  @media(min-width: 768px) and (max-width: 1024px){
    .ehs-datepicker-data{
      top: 19% !important;
      position: absolute !important;
      right: 40px !important;
    }
  }