.accessManagement-checkbox {
    width: 20px;
}

.accessManagement-text {
    width: 10%;
    text-align: center !important;
}

.manageDepartment-button {
    background-color: #fff;
    color: #3C4856;
    border: 1px solid #475467;
    border-radius: 4px;
    padding: 0px 20px;
}

.deviceManagementTitle-wrap {
    display: flex;
    align-items: center;
    gap: 4px;
}

.deviceManagementHeader-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.deviceManagementHeader-wrap .backArrow {
    cursor: pointer;
}

.tableResponsive {
    width: 100%;
    overflow-x: auto;
    padding-top: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .device-box {
        width: auto;

    }

    .hikemm-device-box {
        justify-content: center;
        gap: 34px;
    }

    .bridgeDevice-headerContent {
        margin-top: 19px;
    }

    .deviceManagementHeader-wrap {
        flex-wrap: wrap;
        gap: 15px;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .deviceManagementHeader-wrap {
        flex-wrap: wrap;
        gap: 15px;
    }
}


@media(min-width:768px) and (max-width:1024px) {
    .managementDepartment-button {
        background-color: #0E8951;
        color: #fff;
        margin-top: 0px !important;
        margin-bottom: 8px;
        border: 1px solid #086B3E;
        border-radius: 4px;
        /* letter-spacing: 1.6px; */
        text-transform: capitalize;
        padding: 10px 14px !important;
    }
}