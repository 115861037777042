.addDevice-button.managementDepartment {
  width: 180px !important;
}

.addDepartment-card {
  height: 282px;
  width: auto;
  padding: 12px 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  /* position: relative !important; */
  z-index: 99;
}
.managementDepartment-button {
  background-color: #0E8951;
  color: #fff;
  margin-top: -22px;
  margin-bottom: 8px;
  border: 1px solid #086B3E;
  border-radius: 4px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  padding: 0px 14px;
}
.addDepartmentTitle {
  text-align: center !important;
}

.abcd {
  position: absolute;
  top: 0px;
  right: 0px;
}

.departmentWrap {
  padding-top: 20px;
}

.departmentWrap #exampleInputEmail1::placeholder {
  color: #001323;
  font-size: 14px;
}

#exampleInputEmail1 {
  box-shadow: none;
  border: 1px solid #000000;
  padding: 10px;
}

.form-control:focus {
  border-color: #000000;
}

.departmentWrap-input {
  box-shadow: none;
}

.departmentWrap span {
  color: #e31e24;
}

.addDepartmentButton {
  background-color: #0E8951;
  color: #fff;
  border: 1px solid #086B3E;
  border-radius: 4px;
  width: 180px;
  /* padding: 10px 16px; */
  padding: 10px 30px;
  /* letter-spacing: 1.6px; */
  text-transform: capitalize;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
}

.manageDepartment-card {
  /* height: 750px; */
  min-height: 750px;
}

.manageDepartment-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.manageDepartment-fieldWrap {
  /* padding: 12px; */
  padding: 9px;
  /* height: 40px; */
  /* width: 100%; */
  width: 90%;
  background-color: #f1f1ff;
  position: relative;
  cursor: pointer;
  overflow-wrap: anywhere;
  /* color: #F1F1FF; */
}
.activeDepartmentimg {
  position: absolute;
  right: -4px;
  top: 0px;
  /* height: 42px; */
  height: 38px;
}
.bold-hr {
  border: none;
  border-top: 2px solid #4D5963;
  font-weight: bold;
  
}

.manageDepartment-fieldWrap::before {
  /* content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 29;
    height: 28;
    background-color: red;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0; */
  /* content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2px;
    background-color: #0E8951; */
}
.managementDepartment-card {
  background-color: #f1f1ff;
  min-height: 620px;
  padding: 10px 10px 10px 10px;
}
.managementDepartment-addRoleswrap {
  display: flex;
  justify-content: end;
  align-items: center !important;
  gap: 6px;
  padding: 0px 10px 10px 0px;
}
.manageDepartment-assignRolesText {
  color: #001323;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}

.manageDepartment-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manageDepartment-fieldIcon {
  display: flex;
  justify-content: end;
  align-items: center !important;
  gap: 12px;
}
.managementDepartment.roles {
  display: flex;
  justify-content: space-between;
}

/* ========================================== */

.manageDepartment-card.roles {
  background-color: #f1f1ff;
  height: 600px;
}
.managementDepartment.icon {
  display: flex;
  gap: 10px;
}
.manageDepartment.roleInputWrap {
  padding-top: 10px;
}
.manageDepartment.roleInput {
  display: flex;
  gap: 30px;
  align-items: center;
  /* justify-content: space-between; */
}

.manageDepartment-input {
  width: 90%;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid #000;
}
.manageDepartment-input::placeholder {
  font-size: 12px;
}

.manageDepartment.addRoleButton {
  background-color: #0E8951;
  border: 1px solid #086B3E;
  color: #fff;
  border-radius: 4px;
  width: 100px;
  padding: 6px;
}

.mangementDepartment label {
  visibility: hidden;
}

.addPlant-cardModal {
  height: auto;
  width: 35%;
  /* padding: 12px 20px; */
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 26px #888888 !important;
  border-radius: 6px !important;
  z-index: 99;
}

.addPlant-title {
  color: #fff;
  font-weight: 500 !important;
}

.closeImg {
  position: absolute;
  right: 20px;
  top: 25px;
}

.addPlant-titleWrap {
  padding: 0;
  text-align: center !important;
  background-color: #0E8951;
  padding: 24px 0px 14px 0px;
}

.addplantMdal-innerWrap {
  padding: 0px 25px 25px 25px;
}

.addplantInputfield {
  width: 100%;
}

.addPlant-cardButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.addPlantBtn {
  background-color: #0E8951;
  color: #fff;
  border: 1px solid #086B3E;
  border-radius: 4px;
  /* width: auto; */
  width: 100%;
  padding: 12px 82px;
  margin-top: 20px;
  /* letter-spacing: 1.8px; */
  text-transform: capitalize;
}


/* ========================================== */

@media (min-width: 899.98px) and (max-width: 1024px) {
  .manageDepartment-fieldWrap {
        width: 100%;
    }
  .manageDepartment-card.roles {
    background-color: #f1f1ff;
    height: auto;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  /* .userpofileCard {
        height: auto;
    } */
  .manageDepartment-fieldWrap {
    width: 100%;
  }
  .manageDepartment-card.roles {
    background-color: #f1f1ff;
    height: auto;
  }
}

@media (min-width: 899px) and (max-width: 1024px) {
  .assignDevice-submitBtnWrap {
    padding-top: 10px;
    padding-bottom: 22px;
  }

  .assignDevice-cardModal {
    height: 450px;
    padding: 0px;
  }

  .assignDevice-submitButton {
    padding: 8px 43px;
  }

  .addPlant-cardModal {
    width: 50%;
    top: 50%;
  }
}

@media (min-width: 767px) and (max-width: 899.98px) {
  .assignDevice-submitBtnWrap {
    padding-top: 10px;
    padding-bottom: 22px;
  }

  .assignDevice-cardModal {
    /* height: auto; */
    /* height: 525px; */
    /* height: 300px;
        min-height: 525px; */
    /* overflow: auto !important; */
    height: 450px;
    width: auto;
    padding: 0px;
    /* padding: 12px 20px; */
    /* padding: 10px 0px 10px 0px; */
    /* position: absolute; */
    /* position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 26px #888888 !important;
        z-index: 1; */
  }

  .selectCompany-dropDown {
    width: 100%;
  }

  .plantmanagementDepartment-fieldWrap {
    width: 100%;
  }

  .addPlant-cardModal {
    width: 50%;
    top: 50%;
  }

  .assignDevice-submitButton {
    padding: 8px 43px;
  }
}
